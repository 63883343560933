module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.13.6/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.easydatasolution.de/","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-plausible@0.0.7_gatsby@5.13.6_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"easydatasolution.de"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.92.0/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/easydatasolution/easydatasolution/apps/homepage/src/cms/cms.js","enableIdentityWidget":false,"publicPath":"admin"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_graphql@16.8.2/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"easy data solution","short_name":"easydatasolution","lang":"de","start_url":"/","background_color":"#FFFFFF","theme_color":"#ee7128","display":"standalone","icon":"/home/runner/work/easydatasolution/easydatasolution/apps/homepage/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c63f1b1d0f33bfddd09b0aeddb5b1d1e"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-offline@6.13.2_gatsby@5.13.6_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.4.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
